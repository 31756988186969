import React, { useState } from "react";
import { ReactFlow, Controls, Background, Handle } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import FlowModal from "../components/FlowModal"; // Import ModuleModal

const ImageNode = ({ data }) => {
  return (
    <div>
      {data.image ? (
        <img
          src={data.image}
          alt={data.label}
          style={{ width: "120px", height: "100px" }}
        />
      ) : (
        <div>{data.label}</div>
      )}
      <Handle type="source" position="right" />
      <Handle type="target" position="left" />
    </div>
  );
};
const moduleData = {
  "SKEYE GATE™": {
    label: "SKEYE GATE™",
    description: "Main Features:",
    definition:
      "SkEYE Gate is the integration layer between all the modules. It is the entry point for data management flow through our Data Foundry and all its components.",
    features: [
      "Modules activation and installation",
      "Access management and security: Granular role-based access control and integration with authentication systems (e.g., LDAP, OAuth)",
      "License activation and management",
      "Modules usage monitoring",
      "Project centralization throughout all the SkEYE modules",
    ],
    image: "/assets/GIFs/SKEYEGATE.gif",
    logo: "/assets/skeye_icons/majesteye-icon-skeye-gate-horizontal-white-background.svg",
    additionalImages: ["/assets/GIFs/SKEYEGATE.gif"],
  },
  "Data Lineage (SKEYE Ingest™)": {
    label: "Data Lineage (SKEYE Ingest™)",
    description: "Main Features:",
    definition:
      "SkEYE Ingest is a data integration/ETL tool designed for building and managing data pipelines. It enables users to ingest, process, and deliver large volumes of data from various sources to multiple destinations in real-time or batch modes.",
    features: [
      "Visual pipeline designer: Drag-and-drop interface for creating data pipelines, with No-code/low-code approach to data integration",
      "Wide range of connectors: Support for various data sources and destinations (databases, cloud services, messaging systems, etc.), with built-in and custom processors for data transformation",
      "Built-in streaming and batch data handling with Support for change data capture scenarios",
      "Distributed execution for high-volume data processing, and horizontal scaling capabilities",
      "Monitoring and management: Lineage tracking and Real-time pipeline monitoring and alerting, generating performance metrics and logging",
      "Extensibility: SDK for developing custom stages and processors",
      "Integration: REST API for programmatic control and integration",
      "Built-in versioning for pipelines, and Import/export functionality for sharing pipelines",
    ],
    image: "/assets/GIFs/SKEYEINGEST.gif",
    logo: "/assets/skeye_icons/majesteye-icon-skeye-ingest-horizontal-white-background.svg",
    additionalImages: [
      "/assets/screenshots/SKEYEINGEST1.png",
      "/assets/screenshots/SKEYEINGEST2.png",
    ],
  },
  "Data Storage (SKEYE Cube™)": {
    label: "Data Storage (SKEYE Cube™)",
    description: "Main Features:",
    definition:
      "SkEYE Cube is a distributed analytics engine designed to provide SQL interface and multi-dimensional analysis (OLAP). It's built to bridge the gap between Big Data exploration and traditional data warehousing enabling interactive analysis of massive datasets with sub-second query latency.",
    features: [
      "Ability to handle datasets with billions of rows, and Distributed architecture for parallel processing",
      "Built on top of the Hadoop ecosystem, through native support for HDFS, Hive, and Apache Spark",
      "Aggregation group and dimension hierarchy for storage optimization, and incremental building of cubes for efficient updates",
      "Programmatic access through REST API for query execution and administration",
      "Built-in monitoring for query performance analysis and system health",
    ],
    image: "/assets/GIFs/SKEYECUBE.gif",
    logo: "/assets/skeye_icons/majesteye-icon-skeye-cube-horizontal-white-background.svg",
    additionalImages: [
      "/assets/screenshots/SKEYECUBE1.png",
      "/assets/screenshots/SKEYECUBE2.png",
    ],
  },

  "EDA (SKEYE Note™)": {
    label: "SkEYE Note™",
    description: "Main Features:",
    definition:
      "SkEYE Note is a web-based notebook that enables interactive data analytics sketching. It provides an integrated environment for data ingestion, exploration, visualization, sharing and collaboration. SkEYE Note supports multiple languages and data processing backends, making it a versatile tool for collaborating data scientists, analysts, and engineers to work with data interactively.",
    features: [
      "Multi-language support: Interpreters for various languages (e.g., Scala, Python, R, SQL) with ability to mix multiple languages within a single notebook",
      "Ad-hoc Data visualization, with built-in visualization tools for creating charts and graphs, and support for dynamic forms and interactive widgets",
      "Web-based UI for creating and sharing notebooks, with real-time collaboration features",
      "Native support for big data ecosystems (Hadoop, Spark, Hive, Flink, ...)",
      "Plugin system for adding new interpreters and visualizations, and REST API for programmatic control and integration",
    ],
    image: "/assets/GIFs/SKEYENOTE.gif",
    logo: "/assets/skeye_icons/majesteye-icon-skeye-note-horizontal-white-background.svg",
    additionalImages: [
      "/assets/screenshots/SKEYENOTE1.png",
      "/assets/screenshots/SKEYENOTE2.png",
    ],
  },
  "Dashboarding (SKEYE Charts™)": {
    label: "Dashboarding (SKEYE Charts™)",
    description: "Main Features:",
    definition:
      "SkEYE Charts is the Business Intelligence module designed to be user-friendly, feature-rich, and enterprise-ready. It allows users to create interactive dashboards and perform ad-hoc analysis with limited programming skills.",
    features: [
      "Modern, intuitive user interface, with Drag-and-drop dashboard creation",
      "Wide range of visualization options with possibility of custom visualizations",
      "Rich rendering options in the form of dashboards, reports, website embeddings, and direct integration with other applications via programmatic access through REST APIs",
      "Responsive layouts for multi-channel access (laptop, mobile, tablet…)",
      "Powerful data exploration capabilities with SQL IDE for writing and executing queries",
      "Support for a wide range of database backends",
      "Granular role-based access control and integration with authentication systems (e.g., LDAP, OAuth)",
    ],
    image: "/assets/GIFs/SKEYECHARTS.gif",
    logo: "/assets/skeye_icons/majesteye-icon-skeye-charts-horizontal-white-background.svg",
    additionalImages: [
      "/assets/screenshots/SKEYECHARTS1.png",
      "/assets/screenshots/SKEYECHARTS2.png",
    ],
  },
  "Predictive Analytics (SKEYE Predict™)": {
    label: "SkEYE Predict™",
    description: "Main Features:",
    definition:
      "SkEYE Predict is an Artificial Intelligence system for predictive and prescriptive analysis, that is able to generate concise and highly performant predictive models. SkEYE Predict offers a no-code web interface to run and monitor machine learning projects. Enabling data scientists and business experts to collaborate, run experiments, and generate predictive and self-explainable machine learning models.",
    features: [
      "Flawless execution of machine learning experiments",
      "Experiment tracking, training metrics exploration and model evaluation",
      "Data indexing to speed up data exploration and model training",
      "Native support for the SkEYE Ingest staging area",
      "High performance in predictive results that doesn’t leave any knowledge in data uncovered",
      "Generate models in a human-readable and self-explainable format to ensure high-level business adherence to the predictive process",
      "Offers a REST API to access/download pretrained models",
    ],
    image: "/assets/GIFs/SKEYEPREDICT.gif",
    logo: "/assets/skeye_icons/majesteye-icon-skeye-predict-horizontal-white-background.svg",
    additionalImages: [
      "/assets/screenshots/SKEYEPREDICT1.png",
      "/assets/screenshots/SKEYEPREDICT2.png",
    ],
    subModules: {
      "EDA (SKEYE Note™)": {
        label: "EDA (SKEYE Note™)",
        description: "Main Features:",
        definition:
          "SkEYE EDA (for Exploratory Data Analysis) is an innovative product designed to bridge the gap between machine learning models and domain expertise. It provides a platform for visualizing populations subject to predictions, enabling experts to test hypotheses and evaluate them using metrics typically employed in machine learning model assessment. SkEYE EDA empowers decision-makers to leverage both data-driven insights and human expertise for more informed and accurate decision-making.",
        features: [
          "Interactive displays of the prediction subject population, allowing for deep exploration of data characteristics and distributions",
          "Tools for experts to formulate and test hypotheses based on their domain knowledge and intuition",
          "Incorporation of standard machine learning evaluation metrics to assess hypothesis performance",
          // "Ability to compare multiple hypotheses side-by-side, facilitating the identification of the most effective approaches", 
          // "Features that enable multiple experts to work together, share insights, and build upon each other's hypotheses",
          "Capabilities to slice and dice the population data for more granular analysis and hypothesis testing",
          "Visualization and explanation features to help experts understand the reasoning behind predictions and hypothesis outcomes"
        ],
        image: "/assets/GIFs/EEDA.gif",
        logo: "/assets/skeye_icons/majesteye-icon-skeye-eda-horizontal-white-background.svg",
        additionalImages: [
          "/assets/screenshots/DGHELPER1.png",
          "/assets/screenshots/SKEYEEDA2.png",
        ],
      },
      "KBASE™": {
        label: "KBASE™",
        description: "Main Features:",
        definition:
          "KBase (for Knowledge Base) is a centralized platform designed to store, manage, and distribute machine learning models along with their associated metrics and rules. This tool serves as a comprehensive archive for data science teams, allowing them to export models as JSON files for seamless integration into various data products. By providing a structured approach to model management, the KBase enhances collaboration, reproducibility, and deployment efficiency in machine learning workflows.",
        features: [
          "Ability to export pretrained models to production",
          "Centralized Storage: Secure storage for all ML models, including different versions and iterations",
          // "Metadata Management: Capability to store and manage comprehensive metadata for each model, including training data information, hyperparameters, and performance metrics", 
          "JSON Export: Functionality to export models and their associated data as JSON files, enabling easy integration with data products and other systems",
          "Performance Metrics Dashboard: Visual representation of model performance metrics, allowing for quick comparison and evaluation",
          "Rule Storage: Ability to store and manage business rules and constraints associated with each model",
          //"Model Comparison: Side-by-side comparison of different models or versions to aid in selection and decision-making processes"
        ],
        image: "/assets/screenshots/KBASE1.png",
        logo: "/assets/skeye_icons/majesteye-icon-k-base-horizontal-white-background.svg",
        additionalImages: ["/assets/screenshots/KBASE2.png"],
      },
    },
  },
  "Business goals (DG Helper)": {
    label: "Business goals (DG Helper)",
    definition:
      "DGHelper is a web application that enables data governance monitoring at Organization level. It provides an integrated environment for data governance supervision, team collaborations, and documents sharing. DGHelper has built-in support for MajestEYE’s data governance framework, GovernEYE. DGHelper is a versatile tool for data practitioners to seamlessly implement Data Governance practices.",
    description: "Main Features:",
    features: [
      "Native support for the GovernEYE framework",
      // "Team collaborations and Data Governance documents sharing",
      "Supports for GovernEYE tools (Data Quality Issue Reports, Personal Information Access Request Reports, Fahras, etc.)",
      "Role-based access control and Data Management Office Management",
    ],
    image: "/assets/screenshots/DGHELPER1.png",
    logo: "",
    additionalImages: [
      "/assets/screenshots/DGHELPER2.png",
      "/assets/screenshots/DGHELPER3.png",
    ],
  },
  "Use Case book (DG Helper)": {
    label: "Use Case Book (DG Helper)",
    definition:
      "DGHelper is a web application that enables data governance monitoring at Organization level. It provides an integrated environment for data governance supervision, team collaborations, and documents sharing. DGHelper has built-in support for MajestEYE’s data governance framework, GovernEYE. DGHelper is a versatile tool for data practitioners to seamlessly implement Data Governance practices.",
    description: "Main Features:",
    features: [
      "Native support for the GovernEYE framework",
      // "Team collaborations and Data Governance documents sharing",
      "Supports for GovernEYE tools (Data Quality Issue Reports, Personal Information Access Request Reports, Fahras, etc.)",
      "Role-based access control and Data Management Office Management",
    ],
    image: "/assets/screenshots/DGHELPER1.png",
    logo: "",
    additionalImages: [
      "/assets/screenshots/DGHELPER2.png",
      "/assets/screenshots/DGHELPER3.png",
    ],
  },
  "Data Strategy (DG Helper)": {
    label: "Data Strategy (DG Helper)",
    definition:
      "DGHelper is a web application that enables data governance monitoring at Organization level. It provides an integrated environment for data governance supervision, team collaborations, and documents sharing. DGHelper has built-in support for MajestEYE’s data governance framework, GovernEYE. DGHelper is a versatile tool for data practitioners to seamlessly implement Data Governance practices.",
    description: "Main Features:",
    features: [
      "Native support for the GovernEYE framework",
      // "Team collaborations and Data Governance documents sharing",
      "Supports for GovernEYE tools (Data Quality Issue Reports, Personal Information Access Request Reports, Fahras, etc.)",
      "Role-based access control and Data Management Office Management",
    ],
    image: "/assets/screenshots/DGHELPER1.png",
    logo: "",
    additionalImages: [
      "/assets/screenshots/DGHELPER2.png",
      "/assets/screenshots/DGHELPER3.png",
    ],
  },
  "UC prioritized (DG Helper)": {
    label: "UC prioritized (DG Helper)",
    definition:
      "DGHelper is a web application that enables data governance monitoring at Organization level. It provides an integrated environment for data governance supervision, team collaborations, and documents sharing. DGHelper has built-in support for MajestEYE’s data governance framework, GovernEYE. DGHelper is a versatile tool for data practitioners to seamlessly implement Data Governance practices.",
    description: "Main Features:",
    features: [
      "Native support for the GovernEYE framework",
      // "Team collaborations and Data Governance documents sharing",
      "Supports for GovernEYE tools (Data Quality Issue Reports, Personal Information Access Request Reports, Fahras, etc.)",
      "Role-based access control and Data Management Office Management",
    ],
    image: "/assets/screenshots/DGHELPER1.png",
    logo: "",
    additionalImages: [
      "/assets/screenshots/DGHELPER2.png",
      "/assets/screenshots/DGHELPER3.png",
    ],
  },
  "Data catalog (Fahras)": {
    label: "Data catalog (Fahras)",
    definition:
      "Fahras is a web-based data catalog under the umbrella of GovernEYE. Fahras supports data teams collaborations, enables metadata and data catalog documentation through its wide range of connectors.",
    description: "Main Features:",
    features: [
      // "High quality data assetization pipelines",
      "Data catalog and metadata documentation",
      "Collaborations and sharing between data teams",
      "Automated metadata ingestion pipelines",
      "Role-based UI personalization based on access permissions (personas)",
      // "Integrates with messaging apps (Slack, Google groups, etc.)",
      "Data classification/tagging"
    ],
    image: "/assets/screenshots/FAHRAS1.png",
    logo: "",
    additionalImages: [
      "/assets/screenshots/FAHRAS2.png",
      "/assets/screenshots/FAHRAS3.png",
      "/assets/screenshots/FAHRAS4.png",
      "/assets/screenshots/FAHRAS5.png",
    ],
  },
  "DG Assessment (DG Scan)": {
    label: "DG Assessment (DG Scan)",
    definition:
      "DG Scan is a hierarchical, web-based structure used to evaluate Organization’s Data Governance practices. Companies can score their maturity level across different dimensions of data governance, either strategy or execution. The tool provides insight into whether an organization adheres to established data governance standards, enabling prioritization of actions based on their importance.",
    description: "Main Features:",
    features: [
      "Hierarchical Structure, starting from Categories, to Domains, to Controls, to specifications down to requirements",
      "Collection of data on specific aspects of the company’s governance practices, for maturity scoring",
      "Assessment of company’s practices alignment with regulatory standards for data governance",
      "Distinction between strategy (the vision, policies, and governance frameworks established) and execution (the operational implementation of Data Governance)",
      "Enabling organizations to assess their baseline on Data Governance and prioritize efforts to improve compliance and operational effectiveness",
      "Feeding a reporting dashboard providing stakeholders with insights into data governance maturity, highlighting strengths, weaknesses, and progress",
    ],
    image: "/assets/screenshots/DGSCAN2.png",
    logo: "",
    additionalImages: ["/assets/screenshots/DGSCAN2.png"],
  },
  "Data Quality (QualitEYE™)": {
    label: "Data Quality (QualitEYE™)",
    definition:
      "QualifEYE is an solution for ensuring data quality, addressing the growing need for reliable data in driving business innovation and progress.",
    description: "Main Features:",
    features: [
      "Automating the testing of datasets, reducing manual effort and ensuring consistent data quality across the organization",
      //"Continuously monitoring datasets for anomalies or discrepancies and flags issues to maintain data integrity and prompt timely responses",
      "Implementing essential data quality rules, allowing organizations to define and customize criteria based on specific requirements and industry standards",
      "Providing guidance to define quality rules that serve as directives for data profiling",
      "Providing connectors to data sources",
      //"Providing real-time insights via a dashboard, using advanced data visualization techniques to track key performance indicators (KPIs), trends, and anomalies related to data quality",
    ],
    image: "/assets/screenshots/DQ/DQ1.png",
    logo: "",
    additionalImages: [
      "/assets/screenshots/DQ/DQ2.png",
      "/assets/screenshots/DQ/DQ3.png",
      "/assets/screenshots/DQ/DQ4.png",
      "/assets/screenshots/DQ/DQ5.png",
    ],
  },
};
const initialNodes = [
  {
    id: "1",
    type: "imageNode",
    data: { label: "GOVERNEYE™", image: "/assets/svg/governeye.svg" },
    position: { x: 0, y: 20 },
    style: { backgroundColor: "#0D2E4D", color: "#e6eaed" },
  },
  {
    id: "2",
    data: { label: "Business goals (DG Helper)" },
    position: { x: 160, y: 0 },
    style: { backgroundColor: "#0D2E4D", color: "#e6eaed", fontSize: "17px" },
    targetPosition: "left",
    sourcePosition: "right",
  },
  {
    id: "3",
    data: { label: "DG Assessment (DG Scan)" },
    position: { x: 160, y: 100 }, // Adjusted position to be under "Business goals"
    style: { backgroundColor: "#0D2E4D", color: "#e6eaed", fontSize: "17px" },
    targetPosition: "left",
    sourcePosition: "right",
  },
  {
    id: "4",
    data: { label: "Data Strategy (DG Helper)" },
    position: { x: 340, y: 50 },
    style: { backgroundColor: "#0D2E4D", color: "#e6eaed", fontSize: "17px" },
    targetPosition: "left",
    sourcePosition: "right",
  },
  {
    id: "5",
    data: { label: "Use Case book (DG Helper)" },
    position: { x: 515, y: 50 },
    style: { backgroundColor: "#0D2E4D", color: "#e6eaed", fontSize: "17px" },
    targetPosition: "left",
    sourcePosition: "right",
  },
  {
    id: "6",
    data: { label: "UC prioritized (DG Helper)" },
    position: { x: 700, y: 50 },
    style: { backgroundColor: "#0D2E4D", color: "#e6eaed", fontSize: "17px" },
    targetPosition: "left",
    sourcePosition: "right",
  },
  {
    id: "7",
    data: { label: "Data catalog (Fahras)" },
    position: { x: 900, y: 50 },
    style: { backgroundColor: "#0D2E4D", color: "#e6eaed", fontSize: "17px" },
    targetPosition: "bottom",
    sourcePosition: "right",
  },
  {
    id: "8",
    data: { label: "Data Quality (QualitEYE™)" },
    position: { x: 1100, y: 50 },
    style: { backgroundColor: "#0D2E4D", color: "#e6eaed", fontSize: "17px" },
    targetPosition: "left",
    sourcePosition: "right",
  },
  {
    id: "9",
    type: "imageNode",
    data: {
      label: "SKEYE GATE™",
      image: "/assets/svg/skeye_gate-cropped.svg",
    },
    position: { x: 250, y: 330 },
    style: { backgroundColor: "#8697a6", color: "#e6eaed", fontSize: "17px" },
    targetPosition: "left",
    sourcePosition: "right",
  },
  {
    id: "10",
    data: { label: "EDA (SKEYE Note™)" },
    position: { x: 470, y: 355 },
    style: { backgroundColor: "#8697a6", color: "#0D2E4D", fontSize: "17px" },
    targetPosition: "left",
    sourcePosition: "right",
  },
  {
    id: "11",
    data: { label: "Data Products Generation" },
    position: { x: 675, y: 250 },
    style: {
      backgroundColor: "transparent",
      color: "#0D2E4D",
      width: "600px",
      height: "280px",
      fontSize: "15px",
    },
    sourcePosition: "right",
  },
  {
    id: "12",
    data: { label: "Data Lineage (SKEYE Ingest™)" },
    position: { x: 700, y: 350 },
    style: { backgroundColor: "#8697a6", color: "#0D2E4D", fontSize: "17px" },
    targetPosition: "left",
    sourcePosition: "right",
  },
  {
    id: "13",
    data: { label: "Data Storage (SKEYE Cube™)" },
    position: { x: 1100, y: 300 },
    style: { backgroundColor: "#8697a6", color: "#0D2E4D", fontSize: "17px" },
    targetPosition: "left",
    sourcePosition: "right",
  },
  {
    id: "14",
    data: { label: "Dashboarding (SKEYE Charts™)" },
    position: { x: 900, y: 350 },
    style: { backgroundColor: "#8697a6", color: "#0D2E4D", fontSize: "17px" },
    targetPosition: "left",
    sourcePosition: "right",
  },
  {
    id: "15",
    data: { label: "Predictive Analytics (SKEYE Predict™)" },
    position: { x: 1100, y: 400 },
    style: {
      backgroundColor: "#8697a6",
      color: "#0D2E4D",
      fontSize: "17px",
    },
    targetPosition: "left",
    sourcePosition: "right",
  },
  {
    id: "16",
    data: { label: "Data Governance Flow" },
    position: { x: 40, y: 340 },
    style: { backgroundColor: "#0D2E4D", color: "#fff", fontSize: "17px" },
    targetPosition: "left",
    sourcePosition: "right",
  },
  {
    id: "17",
    data: { label: "Data management Flow" },
    position: { x: 40, y: 400 },
    style: { backgroundColor: "#8697a6", color: "#0D2E4D", fontSize: "17px" },
    targetPosition: "left",
    sourcePosition: "right",
  },
  {
    id: "18",
    data: { label: "LEGEND" },
    position: { x: 35, y: 305 },
    style: {
      backgroundColor: "transparent",
      color: "#0D2E4D",
      width: "160px",
      height: "200px",
      fontSize: "17px",
    },
  },
];

const initialEdges = [
  {
    id: "e1-2",
    source: "1",
    target: "2",
    animated: true,
    style: { stroke: "#D97A07", strokeWidth: 3 },
    markerEnd: { type: "arrow", color: "#D97A07" },
  },
  {
    id: "e1-3",
    source: "1",
    target: "3",
    animated: true,
    style: { stroke: "#D97A07", strokeWidth: 3 },
    markerEnd: { type: "arrow", color: "#D97A07" },
  },
  {
    id: "e2-4",
    source: "2",
    target: "4",
    animated: true,
    style: { stroke: "#D97A07", strokeWidth: 3 },
    markerEnd: { type: "arrow", color: "#D97A07" },
  },
  {
    id: "e4-5",
    source: "4",
    target: "5",
    animated: true,
    style: { stroke: "#D97A07", strokeWidth: 3 },
    markerEnd: { type: "arrow", color: "#D97A07" },
  },
  {
    id: "e5-5",
    source: "5",
    target: "6",
    animated: true,
    style: { stroke: "#D97A07", strokeWidth: 3 },
    markerEnd: { type: "arrow", color: "#D97A07" },
  },
  {
    id: "e6-7",
    source: "6",
    target: "7",
    animated: true,
    style: { stroke: "#D97A07", strokeWidth: 3 },
    markerEnd: { type: "arrow", color: "#D97A07" },
    targetPosition: "left",
  },
  {
    id: "e7-8",
    source: "7",
    target: "8",
    animated: true,
    style: { stroke: "#D97A07", strokeWidth: 3 },
    markerEnd: { type: "arrow", color: "#D97A07" },
  },
  {
    id: "e3-4",
    source: "3",
    target: "4",
    animated: true,
    style: { stroke: "#D97A07", strokeWidth: 3 },
    markerEnd: { type: "arrow", color: "#D97A07" },
  },
  {
    id: "e4-9",
    source: "4",
    target: "9",

    style: { stroke: "#8697a6", strokeWidth: 3 },
    markerEnd: { type: "arrow", color: "#8697a6" },
  },
  {
    id: "e6-10",
    source: "6",
    target: "10",
    style: { stroke: "#8697a6", strokeWidth: 3 },
    markerEnd: { type: "arrow", color: "#8697a6" },
  },
  {
    id: "e9-10",
    source: "9",
    target: "10",
    animated: true,
    style: { stroke: "#0D2E4D", strokeWidth: 3 },
    markerEnd: { type: "arrow", color: "#0D2E4D" },
  },
  {
    id: "e10-7",
    source: "10",
    target: "7",

    style: { stroke: "#8697a6", strokeWidth: 3 },
    markerEnd: { type: "arrow", color: "#8697a6" },
  },
  {
    id: "e11-7",
    source: "11",
    target: "7",

    style: { stroke: "#8697a6", strokeWidth: 3 },
    markerEnd: { type: "arrow", color: "#8697a6" },
  },
  {
    id: "e10-12",
    source: "10",
    target: "12",
    animated: true,
    style: { stroke: "#0D2E4D", strokeWidth: 3 },
    markerEnd: { type: "arrow", color: "#0D2E4D" },
  },
  {
    id: "e6-11",
    source: "6",
    target: "11",
    animated: true,
    style: { stroke: "#0D2E4D", strokeWidth: 3 },
    markerEnd: { type: "arrow", color: "#0D2E4D" },
  },

  {
    id: "e8-11",
    source: "8",
    target: "11",

    style: { stroke: "#8697a6", strokeWidth: 3 },
    markerEnd: { type: "arrow", color: "#0D2E4D" },
  },
  {
    id: "e14-13",
    source: "14",
    target: "13",
    animated: true,
    style: { stroke: "#0D2E4D", strokeWidth: 3 },
    markerEnd: { type: "arrow", color: "#0D2E4D" },
  },
  {
    id: "e12-14",
    source: "12",
    target: "14",
    animated: true,
    style: { stroke: "#0D2E4D", strokeWidth: 3 },
    markerEnd: { type: "arrow", color: "#0D2E4D" },
  },
  {
    id: "e14-15",
    source: "14",
    target: "15",
    animated: true,
    style: { stroke: "#0D2E4D", strokeWidth: 3 },
    markerEnd: { type: "arrow", color: "#0D2E4D" },
  },
];

function FlowDiagram() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedModuleData, setSelectedModuleData] = useState(null);

  const handleNodeClick = (event, node) => {
    const module = moduleData[node.data.label];
    if (module) {
      setSelectedModuleData(module);
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedModuleData(null);
  };

  return (
    <div style={{ height: "80vh", width: "100%", backgroundColor: "#ffffff" }}>
      <ReactFlow
        nodes={initialNodes}
        edges={initialEdges}
        nodeTypes={{ imageNode: ImageNode }}
        onNodeClick={handleNodeClick}
        fitView
      >
        <Controls />
        <Background />
      </ReactFlow>
      <FlowModal
        isModalOpen={isModalOpen}
        selectedModuleData={selectedModuleData}
        closeModal={closeModal}
      />
    </div>
  );
}

export default FlowDiagram;
