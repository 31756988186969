import React, { useState } from "react";
import Slider from "react-slick";

const FlowModal = ({ isModalOpen, selectedModuleData, closeModal }) => {
  const [subModalOpen, setSubModalOpen] = useState(false);
  const [subModule, setSubModule] = useState(null);

  if (!isModalOpen || !selectedModuleData) return null;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const openSubModal = (subModule) => {
    setSubModule(subModule);
    setSubModalOpen(true);
  };

  const closeSubModal = () => {
    setSubModalOpen(false);
    setSubModule(null);
  };

  return (
    <>
      <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50">
        <div className="bg-white w-3/4 h-3/4 p-6 rounded-lg overflow-y-auto overflow-x-hidden">
          <div className="flex justify-between mb-4">
            {selectedModuleData?.logo ? (
              <img
                src={selectedModuleData.logo}
                alt={`${selectedModuleData.label} Logo`}
                className="h-20"
              />
            ) : (
              <h1 className="font-[venera-700] text-2xl">
                {selectedModuleData.label}
              </h1>
            )}
            <button
              onClick={closeModal}
              className="text-blue-900 font-bold border border-blue-900 w-32 h-12 rounded transition-all duration-300 hover:bg-blue-900 hover:text-white"
            >
              GO BACK
            </button>
          </div>
          {/* Sub-modules for SkEYE Predict™ */}
          {selectedModuleData.label === "SkEYE Predict™" && (
            <div className="flex justify-around mb-4">
              <button
                onClick={() => openSubModal("EDA (SKEYE Note™)")}
                className="text-blue-900 font-bold border border-blue-900 w-32 h-12 rounded transition-all duration-300 hover:bg-blue-900 hover:text-white"
              >
                SkEYE EDA™
              </button>
              <button
                onClick={() => openSubModal("KBASE™")}
                className="text-blue-900 font-bold border border-blue-900 w-32 h-12 rounded transition-all duration-300 hover:bg-blue-900 hover:text-white"
              >
                KBASE™
              </button>
            </div>
          )}
          {/* Text Content: Definition, Description, and Features */}
          <div className="mb-4">
            <p className="mt-2 font-bold">Definition:</p>
            <p>{selectedModuleData.definition}</p>
            <p className="mt-2 font-bold">Main Features:</p>
            <ul className="custom-list mt-2 list-inside">
              {selectedModuleData.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div>

          {/* Carousel for Screenshot(s) */}
          {selectedModuleData.image &&
            selectedModuleData.additionalImages.length > 0 && (
              <div className="w-full flex flex-col items-center justify-center">
                <div className="h-80 w-full">
                  <Slider {...settings}>
                    <div>
                      <img
                        src={selectedModuleData.image}
                        alt={`${selectedModuleData.label} Screenshot`}
                        className="h-80 mx-auto"
                      />
                    </div>
                    {selectedModuleData.additionalImages.map((img, index) => (
                      <div key={index}>
                        <img
                          src={img}
                          alt={`${selectedModuleData.label} Additional Screenshot ${index + 1}`}
                          className="h-80 mx-auto"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            )}
        </div>
      </div>

      {/* Sub-module Modal */}
      {subModalOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white w-3/4 h-3/4 p-6 rounded-lg overflow-y-auto overflow-x-hidden">
            <div className="flex justify-between mb-4">
              <img
                src={selectedModuleData.subModules[subModule].logo}
                alt={`${subModule} Logo`}
                className="h-28"
              />
              <button
                onClick={closeSubModal}
                className="text-blue-900 font-bold border border-blue-900 w-32 h-12 rounded transition-all duration-300 hover:bg-blue-900 hover:text-white"
              >
                GO BACK
              </button>
            </div>

            {/* Text Content: Definition, Description, and Features */}
            <div className="mb-4">
              <p className="mt-2 font-bold">Definition:</p>
              <p>{selectedModuleData.subModules[subModule].definition}</p>
              <p className="mt-2 font-bold">Main Features:</p>
              <ul className="custom-list mt-2 list-inside">
                {selectedModuleData.subModules[subModule].features.map(
                  (feature, index) => (
                    <li key={index}>{feature}</li>
                  )
                )}
              </ul>
            </div>

            {/* Carousel for Screenshot(s) */}
            {selectedModuleData.subModules[subModule].image &&
              selectedModuleData.subModules[subModule].additionalImages.length >
                0 && (
                <div className="w-full flex flex-col items-center justify-center">
                  <div className="h-80 w-full">
                    <Slider {...settings}>
                      <div>
                        <img
                          src={selectedModuleData.subModules[subModule].image}
                          alt={`${subModule} Screenshot`}
                          className="h-80 mx-auto"
                        />
                      </div>
                      {selectedModuleData.subModules[
                        subModule
                      ].additionalImages.map((img, index) => (
                        <div key={index}>
                          <img
                            src={img}
                            alt={`${subModule} Additional Screenshot ${index + 1}`}
                            className="h-80 mx-auto"
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              )}
          </div>
        </div>
      )}
    </>
  );
};

export default FlowModal;
